import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import { Component, BaseComponent, namespace, Watch } from '@zento-lib/components';
import { Form } from 'theme/components/Form/Form';
import { Select } from 'theme/components/Form/Select';
import { AppContextStore, KEY as appKey } from 'theme/@types/zento/stores/applicationContext';

import { ILanguageSwitcher } from './LanguageSwitcher.d';
import style from './style.scss';

const appContextStore = namespace<AppContextStore>(appKey);

/**
 * Language Switcher
 *
 * Component allowing to switch languages for multistore instances
 */
@Component({})
@Form()
export class LanguageSwitcher extends BaseComponent<ILanguageSwitcher, unknown> {
  private static T = {
    languageSwitcherMsgCart: 'language_switcher_msg_cart',
    languageSwitcherMsgUser: 'language_switcher_msg_user',
    cancelAction: 'cancel_action_msg',
    notificationAction: 'notification_action',
    confirmLanguageChange: 'confirm_language_change',
  };

  @appContextStore.Getter('isDesktop')
  private isDesktop: boolean;

  data() {
    const storeView = currentStoreView();

    return {
      languageSwitcherActive: false,
      language: {
        value: storeView.storeCode,
        label: storeView.i18n.fullLanguageName,
      },
    };
  }

  @Watch('language', { deep: true })
  protected onLanguageChange() {
    this.changeLanguage(this.$data.language.value);
  }

  render() {
    return (
      <span class={style.languageSwitcherContainer}>
        <span
          onClick={this.onToggle}
          class={{
            [style.languageSwitcherTitle]: true,
            [style.languageSwitcherActive]: this.$data.languageSwitcherActive,
          }}
          key='language-switcher-value'>
          {this.getTranslation({ id: this.$data.language.label }) +
            ' (' +
            this.extended.$config.storeViews[this.$data.language.value].i18n.defaultLanguage.toUpperCase() +
            ')'}
        </span>
        <Select
          state={this.$data.language}
          items={this.storeViews}
          name='language-switcher'
          filter={false}
          class={{
            [style.languageSwitcher]: true,
            [style.languageSwitcherActive]: this.$data.languageSwitcherActive,
          }}
          key='language-switcher-select'
        />
      </span>
    );
  }

  /**
   * Array of active store views
   */
  private get storeViews() {
    const config = this.extended.$config;

    return Object.keys(config.storeViews).reduce((ret, storeCode) => {
      if (this.isValidStoreCode(storeCode)) {
        const store = config.storeViews[storeCode];
        ret.push({ value: store.storeCode, label: this.getTranslation({ id: store.i18n.fullLanguageName }) });
      }

      return ret;
    }, [] as Array<{ value: string; label: any }>);
  }

  private changeLanguage(storeCode: string) {
    // TODO: Search string is hardcoded and should be fixed
    const to =
      this.$route.path.startsWith('/en') || this.$route.path.startsWith('/ro')
        ? this.$route.path.substr(3)
        : this.$route.path;

    if (this.$store.state.user.current || this.$store.state.cart.cartItems.length) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: this.getTranslation({
          id: this.$store.state.user.current
            ? LanguageSwitcher.T.languageSwitcherMsgUser
            : LanguageSwitcher.T.languageSwitcherMsgCart,
        }),
        action1: { label: this.getTranslation({ id: LanguageSwitcher.T.cancelAction }), action: 'close' },
        action2: {
          label: this.getTranslation({ id: LanguageSwitcher.T.notificationAction }),
          action: async () => {
            if (!this.$store.state.user.current) {
              // Just clear cart items without sync
              await this.$store.dispatch('cart/clear', { disconnect: true });
              await this.$store.dispatch('cart/sync', { forceClientState: true });
            } else {
              // Logout
              this.broadcast('user-before-logout');
            }

            if (!this.isDesktop) {
              // Close drawer main menu
              this.$store.commit('ui/setSidebar', false);
              document.body.style.overflow = 'visible';
            }

            this.broadcast('store-view-changed', this.extended.$config.storeViews[this.$data.language.value].storeId);
            // Redirect to new route
            this.$router.push(`/redirect?to=${this.extended.localizedRoute(to, storeCode)}`, this.noOp);
          },
        },
        hasNoTimeout: true,
      });
    } else {
      if (!this.isDesktop) {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: this.getTranslation({
            id: LanguageSwitcher.T.confirmLanguageChange,
          }),
          action1: { label: this.getTranslation({ id: LanguageSwitcher.T.cancelAction }), action: 'close' },
          action2: {
            label: this.getTranslation({ id: LanguageSwitcher.T.notificationAction }),
            action: async () => {
              // Close drawer main menu
              this.$store.commit('ui/setSidebar', false);
              document.body.style.overflow = 'visible';

              this.broadcast('store-view-changed', this.extended.$config.storeViews[this.$data.language.value].storeId);
              // Redirect to new route
              this.$router.push(`/redirect?to=${this.extended.localizedRoute(to, storeCode)}`, this.noOp);
            },
          },
          hasNoTimeout: true,
        });
      } else {
        this.broadcast('store-view-changed', this.extended.$config.storeViews[this.$data.language.value].storeId);
        // Just redirect to new route
        this.$router.push(`/redirect?to=${this.extended.localizedRoute(to, storeCode)}`, this.noOp);
      }
    }
  }

  private onToggle() {
    this.$data.languageSwitcherActive = !this.$data.languageSwitcherActive;
  }

  private isValidStoreCode(storeCode: string) {
    const storeView = this.extended.$config.storeViews[storeCode];

    return !!(storeView && typeof storeView === 'object' && !storeView.disabled && storeView.i18n);
  }

  private noOp() {
    // Do nothing
  }
}
